import request from '@/utils/request'

const productApi = {
  GetCategoryAndBrands: '/b/product/category_brands',
  GetCategoryAndBrandsAndGrades: '/b/product/category_brands_grades',
  GetBrandsByCategory: '/b/product/brands',
  GetProducts: '/b/product/products'
}

export function getCategoryAndBrandsAndGrades (params) {
  return request({
    url: productApi.GetCategoryAndBrandsAndGrades,
    method: 'post',
    data: params
  })
}

export function getCategoryAndBrands (params) {
  return request({
    url: productApi.GetCategoryAndBrands,
    method: 'post',
    data: params
  })
}

export function getBrandsByCategory (params) {
  return request({
    url: productApi.GetBrandsByCategory,
    method: 'post',
    data: params
  })
}

export function getProducts (params) {
  return request({
    url: productApi.GetProducts,
    method: 'post',
    data: params
  })
}
