<template>
  <ProductSearch @onSearch="startSearch($event)"></ProductSearch>

  <CategoryNavV2 :categories="categories" :initialSelectedId="currentCategoryId"
                 @onCategoryChanged="categoryChanged"></CategoryNavV2>

  <CommonSliderNav :has-all="true" :items="brands" @onItemChanged="brandChanged" ref="brandSelector"></CommonSliderNav>
  <CommonSliderNav :has-all="true" :items="grades" @onItemChanged="gradeChanged" ref="gradeSelector"></CommonSliderNav>

  <FilterSorter @loadProducts="loadProducts" @sortProducts="sortProducts"></FilterSorter>

  <div class="container-fluid no-scroll my-1">
    <div class="row">
      <div class="px-0 col-12 product d-flex align-items-center mt-1" v-for="product in products"
           v-bind:key="product.id">
        <img class="image" :src="getThumbImageUrl(product.images[1])" @click="showImages(product.images)">
        <div class="info d-flex flex-column justify-content-around ml-1">
          <div class="model fw-bold">
            <span class="badge bg-primary">{{ product.grade }}</span> {{ product.brand }} {{ product.name }}
          </div>
          <div class="spec">
            <span class="attribute">{{ product.attributes }} </span>
          </div>

          <div class="d-flex align-items-center justify-content-between">
            <div class="text-danger">
              <span class="h5">{{ toThousands(product.unit_price) }}</span>
              <span class="small" style="margin-left: 0.2rem;">AED</span>
            </div>
            <fa icon="cart-plus" style="height: 1.2rem;margin-right: 1rem;" @click="addToShoppingCart(product)"></fa>
          </div>
          <div class="small">
                <span class="d-flex align-items-center justify-content-start">
                  <fa icon="map-marker-alt"></fa>
                  <span class="small" style="margin-left: 0.5rem;">{{ product.country }}</span>
                  <span class="small" style="margin-left:0.5rem;">
                    / Stock:
                    <span>{{ product.qty }} pcs</span>
                  </span>
                </span>
          </div>
        </div>
      </div>
      <div v-if="hasMoreItems" class="col-12 text-center my-3 small hover">
        <div class="text-dark" @click="loadMore()">
          <fa icon="angle-double-right"></fa>
          <br>More...
        </div>
      </div>
      <div v-else class="col-12 text-center my-3 small hover">
        <div>
          No more items
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProductSearch from '@/components/product/ProductSearch'
import CategoryNavV2 from '@/components/product/CategoryNavV2'
import FilterSorter from '@/components/product/FilterSorter'

import {
  getBrandsByCategory,
  getCategoryAndBrandsAndGrades,
  getProducts
} from '@/api/product'

import {
  addToCart
} from '@/api/user'
import { useStore } from 'vuex'
import { computed } from 'vue'

import photoSwipe from '@/plugins/photoswipe'
import CommonSliderNav from '@/components/product/CommomSliderNav'

export default {
  name: 'Product',
  components: {
    CommonSliderNav,
    FilterSorter,
    CategoryNavV2,
    ProductSearch
  },

  created () {
    this.loadCategoryAndBrands()
  },

  data () {
    return {
      categories: [],
      brands: [],
      grades: [],
      currentCategoryId: parseInt(this.$route.params.categoryId) || 9, // 1表示第一个category的ID，数据库里最好是1，否则尴尬了
      currentBrandId: undefined, // 0表示所有品牌，默认不选中
      currentGradeId: -1,
      products: [],

      sorters: {},
      filters: {},

      currentPage: 1,
      itemsPerPage: 20,
      totalItems: 0,
      hasMoreItems: true,
      pager: {
        limit: this.itemsPerPage,
        offset: 0
      },

      searchKey: ''
    }
  },

  setup () {
    const store = useStore()
    const user = computed(() => store.state.auth.user)
    return {
      user
    }
  },

  methods: {
    getThumbImageUrl (url) {
      if (!url) {
        return url
      } else {
        return url + '?x-oss-process=style/thumb'
      }
    },

    startSearch (searchKey) {
      this.searchKey = searchKey
      this.loadProducts()
    },

    showImages (images) {
      const swipeItems = []
      for (const key in images) {
        swipeItems.push({
          src: images[key] + '?x-oss-process=style/wm',
          w: 1024,
          h: 1024
        })
      }
      photoSwipe.preview(swipeItems)
    },

    addToShoppingCart (product) {
      const params = {
        product_sku_stock_id: product.id,
        buyer_id: this.user.id,
        seller_id: product.seller_id,
        name: product.name,
        quantity: 1,
        unit_price: product.unit_price,
        status: 0,
        specs: product.attributes,
        country_id: product.country_id,
        country: product.country,
        image: product.images[1],
        grade: product.grade,
        brand: product.brand
      }

      addToCart(params).then(res => {
        if (res.code === 0) {
          this.$toast.success('Successfully added to cart')
        } else if (res.code === -1) {
          this.$toast.info('Already in your cart')
        } else {
          this.$toast.success('Failed to add to cart')
        }
      }).catch(err => {
        console.log(err)
      })
    },

    resetPager () {
      this.currentPage = 1
      this.itemsPerPage = 20
      this.pager.limit = 20
      this.pager.offset = 0
    },

    categoryChanged (id) {
      if (id === this.currentCategoryId) {
        return
      }

      this.currentCategoryId = id
      this.$refs.brandSelector.resetSelection()
      this.$refs.gradeSelector.resetSelection()
      this.currentBrandId = -1
      this.currentGradeId = -1

      getBrandsByCategory({ categoryId: this.currentCategoryId }).then(res => {
        if (res.code === 0) {
          this.brands = res.data.brands
          if (res.data.brands.length < this.itemsPerPage) {
            this.hasMoreItems = false
          }
        }
      }).catch(err => {
        console.log(err)
      })

      this.resetPager()
      this.hasMoreItems = true
      this.loadProducts()
    },

    gradeChanged (id) {
      this.currentGradeId = id
      this.resetPager()
      this.hasMoreItems = true
      this.loadProducts()
    },

    brandChanged (id) {
      this.currentBrandId = id
      this.resetPager()
      this.hasMoreItems = true
      this.loadProducts()
    },

    loadCategoryAndBrands () {
      this.$loading.show('Loading category and brand info...')
      getCategoryAndBrandsAndGrades({ categoryId: this.currentCategoryId })
        .then(res => {
          if (res.code === 0) {
            this.categories = res.data.categories

            // 假如没有选中的类别，就设置第一个为选中
            if (!this.currentCategoryId) {
              if (this.categories.length > 0) {
                this.currentCategoryId = this.categories[0].id
              }
            }

            this.brands = res.data.brands
            this.grades = res.data.grades
            this.loadProducts()
          }
        }).catch(err => {
          console.log(err)
          this.$loading.hide()
        })
    },

    loadProducts (param = undefined) {
      this.$loading.show('Loading...')

      this.filters = { categoryId: this.currentCategoryId }
      if (this.currentBrandId && this.currentBrandId > 0) {
        this.filters = { brandId: this.currentBrandId, ...this.filters }
      }

      if (this.currentGradeId && this.currentGradeId > 0) {
        this.filters = { gradeId: this.currentGradeId, ...this.filters }
      }

      if (this.searchKey && this.searchKey.length > 0) {
        this.filters = { search: this.searchKey, ...this.filters }
      }

      if (param === 'new') {
        delete this.sorters.sold_time
        this.sorters = { publish_date: 'desc', ...this.sorters }
      } else if (param === 'best') {
        delete this.sorters.publish_date
        this.sorters = { sold_time: 'desc', ...this.sorters }
      } else if (param === 'all') {
        delete this.sorters.sold_time
        delete this.sorters.publish_date
      }

      this.resetPager()
      this.hasMoreItems = true

      const params = {
        filters: this.filters,
        sorters: this.sorters,
        pager: this.pager
      }

      // console.log(params)
      getProducts(params).then(res => {
        // console.log(res)
        if (res.code === 0) {
          this.products = res.data.products
          if (this.products.length < this.itemsPerPage) {
            this.hasMoreItems = false
          }
        }
        this.$loading.hide()
      }).catch(err => {
        this.$loading.hide()
        console.log(err)
      })
    },

    loadMore () {
      this.$loading.show()
      this.pager.offset += this.itemsPerPage
      const params = {
        filters: this.filters,
        sorters: this.sorters,
        pager: this.pager
      }

      getProducts(params).then(res => {
        console.log(res)
        if (res.code === 0) {
          if (res.data.products.length < this.itemsPerPage) {
            this.hasMoreItems = false
          }
          this.products.concat(res.data.products)
        }
        this.$loading.hide()
      }).catch(err => {
        this.$loading.hide()
        console.log(err)
      })
    },

    sortProducts (param) {
      if (param === 1) {
        this.sorters.unit_price = 'asc'
      } else if (param === 2) {
        this.sorters.unit_price = 'desc'
      } else {
        delete this.sorters.unit_price
      }
      this.loadProducts()
    }
  }
}
</script>
