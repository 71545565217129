<template>
  <div class="container-fluid no-scroll px-2">
    <div class="container px-0">
      <div class="row">
        <div v-if="items.length > 0" class="slide-menu-wrapper">
          <div class="slide-menu-container">
            <div v-if="hasAll" class="slide-menu-item" :class="{'text-danger fw-bold': selectedId===-1}"
                 @click="selectItem(-1)">
              All
            </div>
            <div class="slide-menu-item" v-for="item in items" v-bind:key="item.id"
                 :class="{'text-danger fw-bold': selectedId===item.id}" @click="selectItem(item.id)">
              {{ item.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

export default {
  name: 'CommonSliderNav',

  props: {
    items: {
      type: Array,
      default: () => []
    },
    hasAll: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      selectedId: -1
    }
  },

  emits: ['onItemChanged'],

  methods: {
    selectItem (id) {
      this.selectedId = id
      this.$emit('onItemChanged', id)
    },

    resetSelection () {
      this.selectedId = -1
    }
  }
}
</script>

<style scoped lang="scss">
.slide-menu-wrapper {
  margin-top: 1px;
  background: white;
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  position: relative;
  padding: 0.6rem 0;
  height: 4rem;

  .slide-menu-container {
    display: inline-block;
    margin: 0.6rem 0;

    .slide-menu-item {
      display: inline;
      margin: 0 0.5rem;
      white-space: nowrap;
      font-size: 0.9rem;
      padding: 0 0.6rem;
      cursor: pointer;
    }
  }
}

</style>
