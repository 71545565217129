<template>
  <div class="container-fluid no-scroll bg-white py-3">
    <div class="container px-0">
      <div class="row">
        <div class="col-12">
          <div class="input-group position-relative">
            <input :placeholder="`Search items in ${scope || 'products'}`" class="form-control" v-on:keyup.enter="startSearch" v-model="searchName" type="text">
            <span class="input-group-text bg-trans text-white" @click="startSearch"><fa icon="search"></fa></span>
            <fa v-if="searchName && searchName.length > 0" class="close-icon" icon="times-circle"
                @click="searchName='';startSearch"></fa>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductSearch',

  props: {
    scope: String
  },

  data () {
    return {
      searchName: ''
    }
  },

  emits: ['onSearch'],

  methods: {
    startSearch () {
      this.$emit('onSearch', this.searchName)
    }
  }
}
</script>

<style scoped>

.close-icon {
  position: absolute;
  height: 1.2rem;
  right: 3rem;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0.6;
  z-index: 9999;
}

</style>
