<template>
  <div class="container-fluid no-scroll bg-white py-2" style="margin-top: 1px; height: 2.7rem;">
    <div class="row">
      <div class="col-12 d-flex align-items-center justify-content-between">
        <span class="filter-item" :class="{'active': filter === 'all'}" @click="handleClick('all')">All</span>
<!--        <span class="filter-item" :class="{'active': filter === 'new'}" @click="handleClick('new')">New Arrivals</span>-->
        <!--        <span class="filter-item" :class="{'active': filter === 'best'}" @click="handleClick('best')">Just Sold</span>-->
        <span class="filter-item" :class="{'active': priceSort > 0}" @click="togglePriceSort">Price
          <fa v-if="priceSort === 0" icon="arrows-alt-v" class="priceSort" style="color: gray"></fa>
          <fa v-if="priceSort === 1" icon="sort-numeric-up" class="priceSort"></fa>
          <fa v-if="priceSort === 2" icon="sort-numeric-down" class="priceSort"></fa>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FilterSorter',

  data () {
    return {
      filter: 'all',
      priceSort: 0
    }
  },

  emits: ['loadProducts', 'sortProducts'],

  methods: {
    handleClick (type) {
      this.filter = type
      this.$emit('loadProducts', this.filter)
    },

    togglePriceSort () {
      this.priceSort = (this.priceSort + 1) % 3
      this.$emit('sortProducts', this.priceSort)
    }
  }
}
</script>

<style lang="scss" scoped>

.filter-item {
  font-size: 0.8rem;
  background: #eee;
  padding: 0.5rem 1.5rem;
  border-radius: 0.5rem;
  /*font-weight: bold;*/
  line-height: 0.8rem;

  .priceSort {
    margin-left: 0.4rem;
    height: 1rem;
    width: 1rem;
  }

  &.active {
    background: aqua;
  }
}

</style>
