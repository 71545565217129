<template>
  <div class="container-fluid no-scroll px-2">
    <div class="container px-0">
      <div class="row">
        <div class="slide-menu-wrapper">
          <div class="slide-menu-container">
            <div v-for="category in categories" v-bind:key="category.id" :class="{'active': selectedId === category.id}"
                 class="slide-menu-item"
                 @click="selectCategory(category.id)">
              {{ category.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'CategoryNavV2',

  props: {
    categories: {
      type: Array,
      default: () => []
    }
  },

  data () {
    return {
      selectedId: undefined
    }
  },

  emits: ['onCategoryChanged'],

  methods: {
    setInitialCategoryId (id) {
      this.selectedId = id
    },
    selectCategory (id) {
      if (this.selectedId !== id) {
        this.selectedId = id
        this.$emit('onCategoryChanged', id)
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.slide-menu-wrapper {
  padding: 0.6rem 0;
  margin-top: 1px;
  background: white;
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  position: relative;
  height: 4rem;

  .slide-menu-container {
    display: inline-block;
    margin: 0.6rem 0;

    .slide-menu-item {
      display: inline;
      margin: 0 0.8rem;
      white-space: nowrap;
      cursor: pointer;
      border: 1px solid #F1651A;
      padding: 0.2rem 0.5rem;
      color: #777;

      &.active {
        border: 1px solid #de350b;
        background: #de350b;
        color: white;
      }
    }
  }

  //-ms-overflow-style: none;  /* IE and Edge */
  //scrollbar-width: none;  /* Firefox */
  //
  //::-webkit-scrollbar {
  //  display: none;
  //  background: transparent;
  //  width: 0px;
  //}
}

</style>
